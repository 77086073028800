$c-primary: #035595;
$c-secandary: #035595;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$body-color-overrided: #241a2d;
$base-font-family: 'ProximaNova', sans-serif;
$font-family-heading: 'Brother', sans-serif;

      @font-face {
        font-family: 'Brother';
        src: url('/assets-mobile/fonts/Brother-ExtraBold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/assets-mobile/fonts/ProximaNova-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  position: relative;
  width: 100%;

  .icon {
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -12px;
  }
}
