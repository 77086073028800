$c-primary: #035595;
$c-secandary: #035595;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$body-color-overrided: #241a2d;
$base-font-family: 'ProximaNova', sans-serif;
$font-family-heading: 'Brother', sans-serif;

      @font-face {
        font-family: 'Brother';
        src: url('/assets-mobile/fonts/Brother-ExtraBold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/assets-mobile/fonts/ProximaNova-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.mobile {
  @media only screen and (max-width: 320px) {
    font-size: 14px !important;
  }

  @media only screen and (min-width: 321px) {
    font-size: 16px !important;
  }

  @media only screen and (min-width: 480px) {
    font-size: 20px !important;
  }

  @media only screen and (min-width: 720px) {
    font-size: 24px !important;
  }

  @media only screen and (min-width: 960px) {
    font-size: 28px !important;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 32px !important;
  }

  @media only screen and (min-width: 1440px) {
    font-size: 36px !important;
  }
}

.desktop {
  font-size: 18px !important;
}
