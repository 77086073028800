$c-primary: #035595;
$c-secandary: #035595;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$body-color-overrided: #241a2d;
$base-font-family: 'ProximaNova', sans-serif;
$font-family-heading: 'Brother', sans-serif;

      @font-face {
        font-family: 'Brother';
        src: url('/assets-mobile/fonts/Brother-ExtraBold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'ProximaNova';
        src: url('/assets-mobile/fonts/ProximaNova-Light.otf');
        font-weight: 300;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  font-size: 1rem;
  line-height: 1.25;
  font-family: var(--base-font-family);

  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    margin-bottom: 1.5rem; //24
    text-align: left;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
  }

  .description {
    text-align: left;
  }
}
